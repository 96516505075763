import { useNavigation } from '@remix-run/react';
import { captureException } from '@sentry/remix';
import { useEffect, useRef } from 'react';

import { getFeatureQueryParamArray } from '../../../src/hooks/useFeatureQueryParam';
import { BrowserTimeoutCtrl } from '../../../src/utils/BrowserTimeoutCtrl';
import { cacheAssets } from './cache-assets';

export function Precache(props: { files: string[] }) {
  const navigation = useNavigation();
  const latestNavigation = useRef(navigation);
  latestNavigation.current = navigation;
  const attempted = useRef(false);
  const latest = useRef<string[] | null>(null);
  latest.current = props.files;

  useEffect(() => {
    const files = latest.current;

    if (attempted.current) return;
    if (!files) return;

    attempted.current = true;

    const enabled =
      getFeatureQueryParamArray('precache') === 'any' || import.meta.env.PROD;

    if (!enabled) return;

    const delay = new BrowserTimeoutCtrl();
    delay.set(async () => {
      if (import.meta.env.DEV) console.log('Precache Starting');
      try {
        await cacheAssets({ getFilePaths: () => files });
        if (import.meta.env.DEV) console.log('Precache Completed');
      } catch (cause) {
        if (latestNavigation.current.state === 'idle') {
          // An error could be because the user is navigating while precaching
          // is in progress. Prevent logging errors in this known case.
          captureException(cause);
          console.error(cause);
        }
      }
    }, 5000);
  }, []);

  return null;
}
