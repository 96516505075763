// NOTE(drew): this file is copied from
// https://github.com/sergiodxa/remix-utils/blob/main/src/client/cache-assets.ts,
// and modified to allow specifying the filePaths specifically. The remix
// manifest does not seem to contain all bundles, such as those loaded via
// React.Lazy.

export interface CacheAssetsOptions {
  /**
   * The name of the cache to use inside the browser Cache Storage
   * @default "assets"
   */
  cacheName?: string;
  /**
   * The path prefix for all build assets, if you used a subdomain ensure this
   * is only the pathname part.
   * @default "/build/"
   */
  buildPath?: string;

  /**
   * A function that returns an array of all the file paths to cache. The
   * default implementation looks at the remix manifest.
   * @default () => getFilePathsDefault()
   */
  getFilePaths?: () => string[];
}

/**
 * Caches all JS files built by Remix in a browser cache.
 * This will use the Remix manifest to determine which files to cache.
 * It will get every JS file, get all the already cached URLs, remove any
 * old file, and finally add the new files to the cache.
 *
 * **This can only be run inside entry.client**
 */
export async function cacheAssets({
  cacheName = 'assets',
  buildPath = '/build/',
  getFilePaths = getFilePathsDefault,
}: CacheAssetsOptions = {}) {
  const paths = getFilePaths();
  const cache = await caches.open(cacheName);
  const urls = await getCachedUrls(cache, buildPath);
  await removeOldAssets(cache, paths, urls);
  await addNewAssets(cache, paths, urls);
}

function getFilePathsDefault() {
  try {
    return unique([
      ...Object.values(window.__remixManifest.routes).flatMap((route) => {
        return [route.module, ...(route.imports ?? [])];
      }),
      window.__remixManifest.url,
      window.__remixManifest.entry.module,
      ...window.__remixManifest.entry.imports,
    ]);
  } catch (cause) {
    throw new Error('Failed to get file paths from Remix manifest', { cause });
  }
}

async function getCachedUrls(
  cache: Cache,
  buildPath: CacheAssetsOptions['buildPath'] = '/build/'
) {
  try {
    const keys = await cache.keys();
    return keys
      .map((key) => {
        return new URL(key.url);
      })
      .filter((url) => url.hostname === window.location.hostname)
      .map((url) => url.pathname)
      .filter((pathname) => pathname.startsWith(buildPath));
  } catch (cause) {
    throw new Error('Failed to retrieve cached URLs', { cause });
  }
}

async function removeOldAssets(cache: Cache, paths: string[], urls: string[]) {
  try {
    await Promise.all(
      urls
        .filter((pathname) => !paths.includes(pathname))
        .map((pathname) => cache.delete(pathname))
    );
  } catch (cause) {
    throw new Error('Failed to remove old assets from the cache', { cause });
  }
}

async function addNewAssets(cache: Cache, paths: string[], urls: string[]) {
  try {
    await cache.addAll(paths.filter((path) => !urls.includes(path)));
  } catch (cause) {
    throw new Error('Failed to add new assets to the cache', { cause });
  }
}

function unique<Value>(array: Value[]): Value[] {
  return [...new Set(array)];
}
