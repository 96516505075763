import { useEffect } from 'react';
import { type ScriptDescriptor } from 'remix-utils/external-scripts';

import { getEnv } from '../config/getEnv';
import { marketing } from '../config/marketing';
import { gtagEnsure } from './gtag';

export function awScripts(): ScriptDescriptor[] {
  const config = marketing(getEnv());
  return config.registrationMarketingTrackingEnabled
    ? [
        {
          src: 'https://www.googletagmanager.com/gtag/js?id=AW-10811798990',
          async: true,
        },
      ]
    : [];
}

function awIdentify() {
  gtagEnsure();
  gtag('js', new Date());
  gtag('config', 'AW-10811798990');
}

export function AWIdentify() {
  useEffect(() => {
    awIdentify();
  }, []);
  return null;
}
