import '../src/assets/css/index.css';
import '../src/assets/scss/main.scss';
import '../src/assets/css/markdown/index.css';
import '../src/polyfills';

import { promises as fs } from 'node:fs';
import path from 'node:path';
import url from 'node:url';

import { type LinksFunction } from '@remix-run/node';
import {
  json,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
  useSearchParams,
} from '@remix-run/react';
import {
  captureException,
  captureRemixErrorBoundaryError,
} from '@sentry/remix';
import { type ReactNode, useEffect } from 'react';
import { ClientOnly } from 'remix-utils/client-only';
import {
  ExternalScripts,
  type ExternalScriptsHandle,
} from 'remix-utils/external-scripts';
import { type ManifestChunk } from 'vite';

import CairoFontPath from '../src/assets/fonts/cairo/cairo.ttf';
import { RouteErrorBoundary } from '../src/Error';
import { AWIdentify, awScripts } from '../src/tracking/aw';
import { Precache } from './components/precache/Precache';
import { CLIENT_SAFE_ENV } from './env.both';

export const links: LinksFunction = () => {
  return [{ rel: 'icon', href: '/favicon.ico' }];
};

export const ErrorBoundary = () => {
  const error = useRouteError();
  useEffect(() => {
    if (process.env.NODE_ENV === 'development') console.error(error);
    captureRemixErrorBoundaryError(error);
  }, [error]);

  return (
    <>
      <RouteErrorBoundary error={error} />
    </>
  );
};

export function Layout(props: { children: ReactNode }) {
  return <Document>{props.children}</Document>;
}

async function readManifestForPrecache(): Promise<
  Record<string, ManifestChunk>
> {
  const __dirname = url.fileURLToPath(new URL('.', import.meta.url));

  const filepath = import.meta.env.DEV
    ? // this attempts to use a local build if it exists to aid in testing.
      path.join(__dirname, '..', 'build', '.vite', 'client-manifest.json')
    : path.join(__dirname, '..', '.vite/client-manifest.json');

  try {
    const buf = await fs.readFile(filepath, 'utf-8');
    const data = JSON.parse(buf);
    return data;
  } catch (err) {
    captureException(err);
    return {};
  }
}

function getFileListFromManifest(manifest: Record<string, ManifestChunk>) {
  const all = Object.values(manifest).flatMap((chunk) => {
    const imports = [
      ...(chunk.dynamicImports ?? []),
      ...(chunk.imports ?? []),
    ].filter((name) => name.match(/^_/));

    const withRemixQueryParam = chunk.src?.match(/\?client-route=1/)
      ? [`${chunk.file}?client-route=1`]
      : undefined;

    // For now, do not precache images. They are large.
    if (chunk.file.match(/\.(map|png|gif|webp)$/)) return [];

    return [
      // `file` already has the `assets` prefix
      chunk.file,
      // but `imports` do not. They also have a leading underscore, likely to
      // make it obvious what is a generated bundle vs original import.
      ...imports.map((name) => path.join('assets', name.replace(/^_/, ''))),

      // clientRoutes get a special query param at runtime
      ...(withRemixQueryParam ?? []),

      // include CSS too
      ...(chunk.css ?? []),

      // maybe include images etc?
      // ...(chunk.assets ?? []),

      // add leading root slash
    ].map((p) => `/${p}`);
  });

  const unique = new Set(all);
  return unique;
}

// The root loader is currently only for precaching.
export const shouldRevalidate = () => false;

export async function loader() {
  const result = await readManifestForPrecache();
  const fileList = getFileListFromManifest(result);

  return json({ files: Array.from(fileList) });
}

export const handle: ExternalScriptsHandle<unknown> = {
  scripts: [...awScripts()],
};

export default function Root() {
  return (
    <>
      <Outlet />
    </>
  );
}

function forceHydrationErr01() {
  return `
    // Hydration Error Type 1: modify existing node
    document.querySelector('title').textContent = 'HYDRATION BREAK';
  `;
}

function forceHydrationErr02() {
  return `
    // Hydration Error Type 2: add a new node
    const s = document.createElement('style');
    s.innerHTML = 'div { background-color: red; }';
    document.documentElement.appendChild(s);
  `;
}

function Document(props: { children: ReactNode }) {
  const error = useRouteError();
  const [query] = useSearchParams();
  const attemptHydrationErrors = query.get('force-hydration-errors');

  return (
    <html lang='en'>
      <head>
        <meta charSet='utf-8' />
        <link rel='preconnect' href='/' />
        <link
          rel='preconnect'
          href='https://fonts.gstatic.com'
          crossOrigin='anonymous'
        />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1, maximum-scale=1'
        />
        <meta name='theme-color' content='#000000' />

        <Meta />
        <Links />

        {/* BEGIN FONT OPTIMIZATION

          Fonts are not downloaded until they are _referenced_ by a matching CSS
          style, which means until the CSS arrives they won't start downloading!. We
          _know_ we need these fonts, so preload them as soon as we can!

          More fonts can be added here if they are used on performance-sensitive
          public or landing pages, but please be judicious. Cairo, for example, is
          not present here because it is only used in gameplay. */}
        <link rel='preconnect' href='https://fonts.gstatic.com' />
        <link
          href='https://fonts.googleapis.com/css?family=Montserrat:regular,bold,black&display=optional'
          rel='stylesheet'
        />
        <link
          href='https://fonts.googleapis.com/css?family=Inter:regular,bold,black&display=optional'
          rel='stylesheet'
        />
        <style
          suppressHydrationWarning
          dangerouslySetInnerHTML={{
            // This is a self-served font. We use import the file path in order
            // to consistently hash the filename and efficiently cache in the
            // CDN + browser cache.

            __html: `
              @font-face {
                font-family: Cairo;
                font-weight: 900;
                src: local("Cairo"), url(${JSON.stringify(
                  CairoFontPath
                )}) format("truetype");
              }
            `,
          }}
        />
        {/* END FONT OPTIMIZATION */}

        <style
          suppressHydrationWarning
          dangerouslySetInnerHTML={{
            __html: `
              @keyframes root-loading-fade-in {
                from {
                  opacity: 0;
                }
                to {
                  opacity: 100;
                }
              }
            `,
          }}
        />

        {attemptHydrationErrors ? (
          <script
            type='text/javascript'
            dangerouslySetInnerHTML={{
              __html: `
              ${attemptHydrationErrors === '1' ? forceHydrationErr01() : ``}
              ${attemptHydrationErrors === '2' ? forceHydrationErr02() : ``}
              ${
                attemptHydrationErrors === '3'
                  ? forceHydrationErr01() + forceHydrationErr02()
                  : ``
              }
            `,
            }}
          />
        ) : null}
      </head>

      <body
        className='overscroll-none scrollbar'
        style={{ margin: 0, background: 'black' }}
        suppressHydrationWarning
        data-gman-debug-helper={
          process.env.NODE_ENV === 'development'
            ? JSON.stringify({
                // webgl-lint: prevent tensorflow's webgl backend from trigging
                // this error during dev.
                failUnsetUniforms: false,
              })
            : undefined
        }
      >
        <AWIdentify />
        {props.children}
        <ScrollRestoration />
        <EnvScript data={CLIENT_SAFE_ENV} />
        {error ? null : <Precacher />}
        <Scripts />
        <ExternalScripts />
      </body>
    </html>
  );
}

function Precacher() {
  // The <Document /> is used by the Layout export. The ErrorBoundary _also_
  // uses the Layout, and will error if you try to use a loader from within the
  // ErrorBoundary.
  const data = useLoaderData<typeof loader>();
  return (
    <ClientOnly>
      {() => (data ? <Precache files={data.files} /> : null)}
    </ClientOnly>
  );
}

function EnvScript(props: { data: typeof CLIENT_SAFE_ENV }) {
  return (
    <script
      suppressHydrationWarning
      dangerouslySetInnerHTML={{
        __html: [
          `window.ENV = window.ENV || ${JSON.stringify(props.data)};`,
          'window.TONE_SILENCE_LOGGING = true;',
        ].join('\n'),
      }}
    />
  );
}
